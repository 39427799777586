export function chatBoxWork() {
    const bulleSelector = document.querySelector('.bulle-chat');
    const buttonBulle = document.getElementById('blurOnFocus');
    const chatboxSelector = document.querySelector('.chatbox');
    const inputSelectors = document.querySelectorAll('.chat-form input')

    chatboxSelector.classList.remove('chatbox__noJs');
    chatboxSelector.classList.add('chatbox__Js');

    bulleSelector.addEventListener('click', (e) => {
        if (chatboxSelector.classList.contains('activeChat')) {
            e.currentTarget.classList.remove('bulle-chatJs');
            desactivateChat();
        } else {
            e.currentTarget.classList.add('bulle-chatJs');
            activateChat();
        }
    });

    for (const inputSelector of inputSelectors) {
        inputSelector.addEventListener('focus', (e) => {
            activateChat();
        })
        buttonBulle.addEventListener('blur', (e) => {
            desactivateChat();
        })
    }

    function activateChat() {
        chatboxSelector.classList.add('activeChat');
    }

    function desactivateChat() {
        chatboxSelector.classList.remove('activeChat');
    }
}
