import {chatBoxWork} from "./chatbox";

(function () {
    const cvApp = {
        buttonSelector: document.querySelectorAll('.btn_slider'),
        tabSelector: document.querySelectorAll('.tabContent'),
        tabSelectorLinks: document.querySelectorAll('.tabContent .highlighted-link'),
        buttonYear: document.getElementsByClassName('button-year'),
        buttonExp: document.getElementsByClassName('button-exp'),
        parcoursImg: document.querySelectorAll('.parcours-img img'),
        experienceImg: document.querySelectorAll('.experience-img img'),
        slidersImageFooter: document.getElementsByClassName('footer-slider'),
        slidersButtons: document.querySelectorAll('.wrapper-langages .slider__navlink'),
        dropdownMenus: document.getElementsByClassName('dropdown'),
        allCheckBoxSelectors: document.querySelectorAll('input[type=checkbox]'),
        linkNavs: document.querySelectorAll('.link-only'),
        chatBoxSection: document.querySelector('.chatbox-section .bulle-chat'),
        liDatas: document.querySelectorAll('li[data-year*="20"]'),
        liDatasWorks: document.querySelectorAll('li[data-work]'),
        buttonsReviews: document.getElementsByClassName('slider__navlink'),
        arrowRight: document.querySelector('.arrow-right'),
        arrowLeft: document.querySelector('.arrow-left'),
        sliderReviews: document.querySelector('.slides'),
        tableContainsLinks: document.querySelectorAll('a[data-number]'),
        checkJavascriptIsPresent() {
            const tabContentIdThree = document.getElementById('button3');
            const tabContentIdTwo = document.getElementById('button2');
            const identitySection = document.querySelector('.identity');
            const identityButton = document.querySelector('.identity .butInformation');
            const identityContents = document.getElementsByClassName('content');
            identitySection.classList.remove('identityNoJs');
            identityButton.classList.remove('butInformationNoJs');
            tabContentIdTwo.classList.remove('show');
            tabContentIdThree.classList.remove('show');
            document.body.classList.add('js-enabled');
            for (const identityContent of identityContents) {
                identityContent.classList.remove('contentNoJs');
            }
            for (const tabSelectorElement of this.tabSelector) {
                if (tabSelectorElement.dataset.active !== "1") {
                    tabSelectorElement.classList.add('hidden');
                }
            }
            for (const tabSelectorLink of this.tabSelectorLinks) {
                if (tabSelectorLink.dataset.active !== "1") {
                    tabSelectorLink.tabIndex = -1;
                    tabSelectorLink.ariaHidden = "true";
                }
            }
        },
        unCheck() {
            for (const allCheckBoxSelector of this.allCheckBoxSelectors) {
                const handleClick = () => {
                    allCheckBoxSelector.checked = false;
                };
                for (const dropdownMenu of this.dropdownMenus) {
                    dropdownMenu.addEventListener("click", handleClick);
                }
                for (const linkNav of this.linkNavs) {
                    linkNav.addEventListener("click", handleClick);
                }
            }
        },
        sliderWithoutTimer() {
            for (const sliderButton of cvApp.slidersButtons) {
                sliderButton.addEventListener('click', (e) => {
                    const actualId = e.currentTarget.dataset.order;
                    const slider = document.querySelectorAll('.footer-slider');
                    const sliderActual = slider[actualId - 1];
                    const prevSliderData = sliderActual.dataset.order;
                    for (const sliderElement of slider) {
                        sliderElement.classList.remove('slide-active');
                        if (sliderElement.dataset.order === '1') {
                            sliderElement.dataset.order = prevSliderData;
                        }
                    }
                    sliderActual.classList.add('slide-active');
                    sliderActual.dataset.order = '1';
                    sliderButton.classList.remove('slider-navlink-active');
                    e.currentTarget.classList.add('slider-navlink-active');
                });
            }
        },
        timerConfiguration() {
            setInterval(function () {
                const orders = [4, 1, 2, 3];
                for (const slider of cvApp.slidersImageFooter) {
                    const order = +slider.dataset.order;
                    const orderIndex = orders.indexOf(order);
                    const nextOrderIndex = (orderIndex + 1) % orders.length;
                    slider.classList.toggle('slide-active', order === 4);
                    slider.dataset.order = orders[nextOrderIndex];
                }
                for (const sliderButton of cvApp.slidersButtons) {
                    sliderButton.classList.remove('slider-navlink-active');
                    if (sliderButton.dataset.order === "1") {
                        sliderButton.classList.remove('slider-navlink-active');
                        sliderButton.dataset.order = '4';
                    } else if (sliderButton.dataset.order === "2") {
                        sliderButton.classList.add('slider-navlink-active');
                        sliderButton.dataset.order = '1';
                    } else if (sliderButton.dataset.order === "3") {
                        sliderButton.dataset.order = '2';
                    } else {
                        sliderButton.dataset.order = '3';
                    }
                }
            }, 3000);
        },
        removeClassActiveButtonYear() {
            for (let i = 0; i < this.buttonYear.length; i++) {
                this.liDatas[i].classList.remove('liColorJs');
                this.buttonYear[i].classList.remove('button-year-active');
            }
        },
        removeClassActiveWork() {
            for (let i = 0; i < this.buttonExp.length; i++) {
                this.liDatasWorks[i].classList.remove('liColorJs');
                this.buttonExp[i].classList.remove('button-year-active');
            }
        },
        removeClassActiveReview() {
            for (const buttonsReview of this.buttonsReviews) {
                buttonsReview.classList.remove('slider-navlink-active');
            }
        },
        displayTab(e) {
            const id = e.currentTarget.dataset.number;
            const linkId = e.currentTarget.dataset.link;
            for (const tabSelectorLink of cvApp.tabSelectorLinks) {
                tabSelectorLink.tabIndex = -1;
                tabSelectorLink.ariaHidden = "true";
            }
            for (const tabSelectorElement of cvApp.tabSelector) {
                tabSelectorElement.classList.add("hidden");
                tabSelectorElement.classList.remove("show"); // Supprime la classe "show" si elle est présente
            }
            for (const buttonSelectorElement of cvApp.buttonSelector) {
                buttonSelectorElement.classList.remove("active");
            }
            e.currentTarget.classList.add("active");
            const tabContentElement = document.getElementById(id);
            const tabContentLink = document.getElementById(linkId);
            tabContentLink.tabIndex = 0;
            tabContentLink.ariaHidden = "false";
            tabContentElement.classList.remove("hidden");
            tabContentElement.classList.add("show"); // Ajoute la classe "show" avec une transition
        },
        addEventListener() {
            this.buttonSelector.forEach(btn =>
                btn.addEventListener("click", this.displayTab)
            );
            for (const liData of this.liDatas) {
                liData.addEventListener('click', this.addActiveClassParcours);
                liData.addEventListener('mouseenter', this.addActiveClassParcours);
                liData.addEventListener('mouseleave', this.removeActiveClassParcours);
            }
            for (const liDatasWork of this.liDatasWorks) {
                liDatasWork.addEventListener('click', this.addActiveClassWork);
                liDatasWork.addEventListener('mouseenter', this.addActiveClassWork);
                liDatasWork.addEventListener('mouseleave', this.removeActiveClassWork);
            }
            for (const buttonExpElement of this.buttonExp) {
                buttonExpElement.addEventListener('click', this.addActiveClassWork);
            }
            for (const buttonYearElement of this.buttonYear) {
                buttonYearElement.addEventListener('click', this.addActiveClassParcours);
            }
            for (const buttonReview of this.buttonsReviews) {
                buttonReview.addEventListener('click', function (e) {
                    cvApp.removeClassActiveReview();
                    e.currentTarget.classList.add('slider-navlink-active');
                });
            }
            this.sliderReviews.addEventListener('scroll', (e) => {
                this.removeClassActiveReview();
                if (window.innerWidth >= 995) {
                    this.addClassInScrollSliderDesktop(e);
                } else {
                    this.addClassInScrollSliderMobile(e);
                }
            });
            this.arrowLeft.addEventListener('click', (e) => {
                this.removeClassActiveReview();
                this.addClassInScrollSliderDesktop(e);
            });

            this.arrowRight.addEventListener('click', (e) => {
                this.removeClassActiveReview();
                this.addClassInScrollSliderDesktop(e);
            });
        },
        addActiveClassParcours(e) {
            let buttonSelect = document.querySelector(`button[data-year*="${e.currentTarget.dataset.year}"`);
            let data = e.currentTarget.dataset.order;
            cvApp.removeClassActiveButtonYear();
            buttonSelect.classList.add('button-year-active');
            cvApp.liDatas[data].classList.add('liColorJs');
            for (const img of cvApp.parcoursImg) {
                img.classList.remove('active');
            }
            document.getElementById(data).classList.add('active');
        },
        addActiveClassWork(e) {
            let buttonSelectWork = document.querySelector(`button[data-work*="${e.currentTarget.dataset.work}"`);
            let data = e.currentTarget.dataset.orderwork;
            let dataId = parseInt(data) + 3;
            cvApp.removeClassActiveWork();
            buttonSelectWork.classList.add('button-year-active');
            cvApp.liDatasWorks[data].classList.add('liColorJs');
            for (const img of cvApp.experienceImg) {
                img.classList.remove('active');
            }
            document.getElementById(dataId).classList.add('active');
        },
        removeActiveClassParcours(e) {
            let buttonSelect = document.querySelector(`button[data-year*="${e.currentTarget.dataset.year}"`);
            let data = e.currentTarget.dataset.order;
            cvApp.removeClassActiveButtonYear();
            buttonSelect.classList.remove('button-year-active');
            cvApp.liDatas[data].classList.remove('liColorJs');
        },
        removeActiveClassWork(e) {
            let buttonSelectWork = document.querySelector(`button[data-work*="${e.currentTarget.dataset.work}"`);
            let data = e.currentTarget.dataset.orderwork;
            cvApp.removeClassActiveWork();
            buttonSelectWork.classList.remove('button-year-active');
            cvApp.liDatasWorks[data].classList.remove('liColorJs');
        },
        arrowDisplayActive() {
            if (this.arrowRight.classList.contains('arrow-hidden')) {
                this.arrowRight.classList.remove('arrow-hidden');
                this.arrowLeft.classList.add('arrow-hidden');
            } else {
                this.arrowRight.classList.add('arrow-hidden');
                this.arrowLeft.classList.remove('arrow-hidden');
            }
        },
        addClassToArrow(direction, otherSide) {
            direction.classList.remove('arrow-hidden');
            otherSide.classList.add('arrow-hidden');
        },
        addClassInScrollSliderDesktop(e) {
            const horizontal = e.currentTarget.scrollLeft;
            const lien6 = document.querySelector('a[data-number="6"]');
            const lien1 = document.querySelector('a[data-number="1"]');
            if (horizontal >= 230) {
                this.arrowDisplayActive();
                this.addClassToArrow(this.arrowLeft, this.arrowRight);
                lien6.classList.add('slider-navlink-active');
            } else {
                this.arrowDisplayActive();
                this.addClassToArrow(this.arrowRight, this.arrowLeft);
                lien1.classList.add('slider-navlink-active');
            }
        },
        addClassInScrollSliderMobile(e) {
            const horizontal = e.currentTarget.scrollLeft;
            const values = [50, 360, 720, 1080, 1440, 1800];
            let i = 0;
            while (horizontal > values[i]) {
                i++;
            }
            this.tableContainsLinks[i].classList.add('slider-navlink-active');
        },
        internetExplorerFix() {
            if (document.documentMode) {
                clearInterval(this.timerConfiguration);
            }
        },
        init() {
            document.documentElement.classList.add("js-enabled");
            this.checkJavascriptIsPresent();
            this.timerConfiguration();
            this.addEventListener();
            this.internetExplorerFix();
            this.unCheck();
            this.sliderWithoutTimer();
            chatBoxWork();
        }
    }
    cvApp.init();
})();